/**
 *
 * @param {Array<string>} copy Array of copy, each element is a subsection
 * @param {Array<string>} imageIds Array of base image ids
 * @param {string | undefined} header Optional string
 * @param {Array<{text:string}> | undefined} buttons Optional Array of objects for a button per subsection}
 * @returns An object {copySections: Array<{text:string, imageId:string, button: {text:string}}>, header?:string}
 */
const createSectionData = (copy, imageIds, header, buttons) => {
  const copySections = copy.map((value, index) => ({
    text: value,
    imageId: imageIds[index] ?? undefined,
    button: buttons[index ?? undefined],
  }));

  return {
    copySections,
    header,
  };
};

/**
 * All section data
 */
const section_0 = createSectionData(
  [
    `<b>Framestore Smart Signage brings bespoke solutions and high performance
to digital signage while allowing our clients to efficiently and
impactfully communicate with audiences.</b>
<br/>
<br/>
We are content creation specialists who have a content lead approach to
digital signage. Our expertise lies in producing a variety of
high-quality, dynamic, and complex content, ranging from impactful
messaging to breathtaking experiential visuals. Beyond this we are part of
a creative company who push boundaries and focus on innovation.
<br/>
<br/>
<br/>
`,
    `Opting for Framestore Labs Smart Signage signifies partnering with a B2B
supplier with a well established reputation who provides:
<br/>
<br/>
<b>Reliability and Security</b><br />
We offer a trustworthy and secure platform for your needs
<br/>
<br/>
<b>Bespoke System with Client-Driven Development</b><br />
Our tailored systems involve platform development driven by client
requirements
<br/>
<br/>
<b>End-to-End Service Provision</b><br />
As comprehensive service providers, we bring extensive expertise in
network, IT, and AV infrastructure, delivering a powerful and scalable
solution
<br/>
<br/>
<b>Dedicated Global Human Support</b><br />
Benefit from dedicated global human support to ensure a seamless
experience
<br />
<br/>
<b>Consultancy Services</b><br />
In addition to our core offerings, all of these services are available as
consultancy, providing versatile solutions and advice to meet your
specific needs
<br />
<br/>
`,
  ],
  ["0_1"],
  undefined,
  [{ text: "Get in Touch" }]
);

const section_1 = createSectionData(
  [
    `To create captivating and dynamic visuals, 
    our developers seamlessly incorporate various 
    APIs. This allows you to showcase real-time, 
    branded information, such as local transport 
    updates, weather alerts, event details, and 
    social media feeds, ensuring an engaging experience.
    <br/>
    <br/>
    We have designed an advanced playback system utilising 
    high-end graphics hardware to render complex experiential 
    or data driven animations.
    <br/>
    <br/>
    Our talented team of designers and developers deliver 
    cutting-edge interactive content that supports multi-touch 
    and QR code integration.
    <br/>
    <br/>`,
    `Our AdTech integration is flexible, allowing us to sell 
    commercial space to media buyers whilst reserving time for 
    your own content.
    <br/>
    <br/>
    Reporting and analytics provide valuable insight for media 
    buyers, maximizing the value of your screens.
    <br/>
    <br/> 
`,
  ],
  ["1_1", "1_2"],
  "Content",
  []
);

const section_2 = createSectionData(
  [
    `We use secure, reliable, resilient and scalable enterprise-grade Amazon Web Services so the system is always there and working whilst keeping your data safe. 
    <br/>
    <br/>
    Our CMS allows you to create and schedule your content ready to be published to your fleet of screens.
    <br/>
    <br/>
    <br/>
  `,
  ],
  ["2_1"],
  "Content Management",
  [{ text: "Book a Demo" }]
);

const section_3 = createSectionData(
  [
    `We work closely with you and your 3rd parties to understand your requirements and design your digital signage network. 
      <br/>
      <br/>
      We create AV diagrams, network layouts, and hardware and software integration.
      <br/>
      <br/>
      We work with any format of LED screens, LCD video walls, portrait kiosks etc
      <br/>
      <br/>
      We are resellers of major tech brands, offering competitive pricing.
      <br/>
      <br/>
      <br/>
    `,
  ],
  ["3_1"],
  "Hardware System Design",
  [{ text: "Get in Touch" }]
);

const section_4 = createSectionData(
  [
    `All clients enjoy the advantages of Framestore's client service desk, operated by a highly experienced team. We leverage industry-standard tools such as Jira and Confluence. 
        <br/>
        <br/>
        Our real-time monitoring system tracks the health of your hardware for performance and data communication, sending alerts to avoid unexpected downtime.
        <br/>
        <br/>
        We provide tailored service level agreements, and thanks to Framestore's global presence, we are available around the clock to efficiently resolve any issues you may encounter.
      `,
  ],
  ["4_1"],
  "Support and Maintenence",
  []
);

const section_5 = createSectionData(
  [
    `We build unique relationships to develop forward thinking, long term solutions for your investment.
        <br/>
        We service, iterate and update the technology, archive and create new content for the network.
        <br/>
        <br/>
      `,
  ],
  ["logos"],
  "Clients",
  []
);

const section_6 = createSectionData(
  [
    `We take pride in collaborating with esteemed partners who share our commitment to outstanding design and performance.
        <br/>
        <br/>
        If you're a digital kiosk designer, LED or signage screen manufacturer, an experience designer or planner, and you're seeking partnerships with a CMS technology and content studio, get in contact. Let's explore how a partnership could benefit both you and your clients.
        <br/>
        <br/>
        <br/>
      `,
  ],
  ["logos"],
  "Partnerships",
  [{ text: "Get in Touch" }]
);

/**
 * Add all sectionData objects to list
 */
const sectionData = [section_0, section_1, section_2, section_3, section_4, section_5, section_6];

/**
 *
 * @param {string} className Css classname
 * @returns A div with the supplied className assigned
 */
const createDiv = (className) => {
  const div = document.createElement("div");
  div.className = className;
  return div;
};

/**
 *
 * @param {{header: string | undefined,{text: string; imageId: string | undefined; button: {text: string;} | undefined}}} section
 * @returns All dom elements for section data. Doesn't append to main doc
 */
const createSection = (section) => {
  const newSection = createDiv();
  newSection.className = "section";

  /**
   * Add header
   */
  if (section.header) {
    const sectionHWrap = createDiv("section-h-wrap");
    const sectionH = createDiv("section-h");
    sectionH.innerText = section.header;
    sectionHWrap.append(sectionH);
    newSection.append(sectionHWrap);
  }

  //section-c
  const sectionC = createDiv("section-c");
  newSection.append(sectionC);
  const sectionClip = createDiv("section-clip");
  sectionC.append(sectionClip);

  const imageIds = [];

  section.copySections.forEach((copySection) => {
    const subSection = createDiv("subsection");
    /**
     * Add image and event to subsection
     */
    if (copySection.imageId) {
      imageIds.push(copySection.imageId);

      subSection.id = copySection.imageId;
      //intersection observer???
      const observer = new IntersectionObserver((entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const event = new CustomEvent("image/show", {
              detail: { imageId: copySection.imageId },
            });
            window.dispatchEvent(event);
          }
        })
      );
      observer.observe(subSection);
    }
    const subSectionC = createDiv("subsection-c");
    subSectionC.innerHTML = copySection.text;
    subSection.append(subSectionC);

    /**
     * Add button to subsection
     */
    if (copySection.button) {
      const buttonContainer = createDiv("subsection-button-container");
      const button = document.createElement("button");
      const text = copySection.button.text;
      button.innerText = text;
      buttonContainer.append(button);
      subSection.append(buttonContainer);

      button.onclick = () => {
        let link = "mailto:signage@framestore.com";
        if (text === "Get in Touch") {
          link += "?subject=General%20Enquiry";
        } else if (text === "Book a Demo") {
          link += "?subject=Request%20for%20a%20Demo";
        }
        window.location.href = link;
      };
    }

    sectionClip.append(subSection);
  });

  return { root: newSection, sectionClip, imageIds };
};

const sectionRoot = document.getElementById("section-root");

const allSectionData = sectionData.map((value) => createSection(value));
allSectionData.forEach((sectionData) => sectionRoot.append(sectionData.root));

/**
 * Image show logic
 */
/**
 * Get unique entries with a Set
 */
const allImageIds = [...new Set(allSectionData.map((value) => value.imageIds).flat())];

/**
 * Generate all images
 */
const allImageContainers = allImageIds.map((imageId, index) => {
  /**
   * Generate the new image elements for all standard images, "logos" is already in the template
   * with links associated etc
   */
  if (imageId !== "logos") {
    const root = createDiv("left-im");
    //show first image
    if (index === 0) {
      root.style.opacity = 1;
    }
    const image = new Image();
    image.style.height = "100%";
    image.style.width = "auto";
    image.style.objectFit = "contain";
    image.alt = imageId;
    image.sizes = "50vw";
    image.src = `/styles/img/microsite_image_${imageId}_xSmall.png`;
    image.srcset = `
        /styles/img/microsite_image_${imageId}_xSmall.png  660w,
        /styles/img/microsite_image_${imageId}_small.png  1000w,
        /styles/img/microsite_image_${imageId}_medium.png 1500w,
        /styles/img/microsite_image_${imageId}_large.png  2000w`;

    root.append(image);

    return {
      root,
      image,
      imageId,
    };
  } else {
    /**
     * Get reference to the image root for the logos
     */
    const root = document.getElementById("logos");
    return {
      root,
      image: undefined,
      imageId,
    };
  }
});

/**
 * Append all images to "image-root" except the "logos" (already in template)
 */
const imageRoot = document.getElementById("image-root");
allImageContainers.forEach(
  (imageContainer) => imageContainer.imageId !== "logos" && imageRoot.append(imageContainer.root)
);

/**
 * Show Image state
 */
let currentImageId = "";
window.addEventListener("image/show", (event) => {
  const previousImage = currentImageId;
  currentImageId = event.detail.imageId;
  if (currentImageId !== previousImage) {
    allImageContainers.forEach((imageContainer) => {
      if (imageContainer.imageId === currentImageId) {
        imageContainer.root.style.opacity = 1;

        /**
         * If logos, enable point events
         */
        if (currentImageId === "logos") {
          imageContainer.root.style.pointerEvents = "auto";
        }
      } else {
        imageContainer.root.style.opacity = 0;
        /**
         * Disable all pointer events
         */
        imageContainer.root.style.pointerEvents = "none";
      }
    });
  }
});
(function () {
  const sectionsC = document.getElementsByClassName("section-c");
  const rightElem = document.getElementsByClassName("right-elem")[0];
  const leftElem = document.getElementById("image-root");
  const main = document.getElementsByClassName("main")[0];
  const scrollDown = document.getElementsByClassName("scroll-down")[0];
  const contactSection = document.getElementsByClassName("contact-section")[0];
  const iconLogo = document.getElementsByClassName("icon-logo")[0];

  const setScrollDownOpacity = (opacity) => {
    scrollDown.style.opacity = opacity;
  };

  /////////////// DEBUG UTIL ///////////////////
  class DebugUtil {
    constructor() {
      this.dbg = document.createElement("div");
      this.dbg.style.cssText = `
        color: black;
        background-color: white;
        position: fixed;
        top: 30%;
        width: 50%;
        height: 30%;
        z-index: 100000;
      `;
      document.body.insertBefore(this.dbg, document.body.firstChild);
    }

    update() {
      this.dbg.innerHTML = `
      <div>vendor: ${navigator.vendor}</div>
      <div>userAgent: ${navigator.userAgent}</div>
      <div>platform: ${navigator.platform}</div>
      <div>isBrave: ${!!navigator.brave}</div>
      <div>isChrome: ${isChromeCheck()}</div>
      <div>isIphoneIPad: ${isIphoneIPad()}</div>
      `;
    }
  }

  var isUB = navigator.userAgent.indexOf("UBrowser") !== -1; // UC browser desktop
  var isUC = navigator.userAgent.indexOf("UCBrowser") !== -1; // UC browser mobile
  var iE11 = document.documentMode !== undefined; // IE11
  var isBrave = !!navigator.brave;
  var isDuck = navigator.userAgent.indexOf("DuckDuckGo") !== -1;
  var isOperaMini = navigator.userAgent.indexOf("OPR") !== -1;
  var isSamsung = navigator.userAgent.indexOf("SamsungBrowser") !== -1; // samsung mobile

  function isLandscape() {
    return document.body.clientWidth / document.body.clientHeight > 1;
  }

  function isMobileCheck() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  ////////// SOME LISTENERS ////////////////////////////

  window.addEventListener("orientationchange", () => {
    // console.log("orientation change")
    resetScrollWindow();
  });

  contactSection.addEventListener("click", function () {
    window.location.href = "mailto:signage@framestore.com";
  });

  iconLogo.addEventListener("click", function () {
    rightElem.scrollTop = 0;
    setScrollDownOpacity(1);
  });

  ///////// INITIALIZE CONTENT

  window.addEventListener("load", loadHandler);
  function loadHandler() {
    updateBasedOnBrowser();
    repaint(0);
  }

  // Workaround for empty screen
  function repaint(value) {
    setTimeout(() => {
      rightElem.scrollTop = value + 1;
      rightElem.scrollTop = value;
    }, 0);
  }

  function updateBasedOnBrowser() {
    if (isUB || iE11) {
      document.getElementsByClassName("box")[0].style.position = "fixed";
    }
  }

  function resetScrollWindow() {
    document.documentElement.scrollTop = 0;
  }

  window.addEventListener("scroll", (event) => {
    resetScrollWindow(); // for iphone
  });

  /**
   * Work around for no whole page scroll, listen to wheel event on the image section
   */
  leftElem.addEventListener("wheel", (event) => {
    rightElem.scrollTop += event.deltaY * 0.5;
  });

  /**
   * Work around for no whole page scroll, listen to touch events on leftElem
   */
  let posY = 0;
  let prevPosY = posY;
  leftElem.addEventListener("touchstart", (event) => {
    event.preventDefault();
    posY = event.touches[0].clientY;
    prevPosY = posY;
  });
  leftElem.addEventListener("touchmove", (event) => {
    event.preventDefault();
    prevPosY = posY;
    posY = event.touches[0].clientY;

    const delta = prevPosY - posY;

    rightElem.scrollTop += delta;
  });
  leftElem.addEventListener("touchend", (event) => {
    event.preventDefault();
    posY = 0;
    prevPosY = 0;
  });

  /////////////// SCROLL HANDLING /////////////////////
  document.getElementById("section-root").addEventListener("scroll", scrollHandler, false);

  function scrollHandler(e) {
    if (rightElem.scrollTop > 0) {
      setScrollDownOpacity(0);
    } else {
      setScrollDownOpacity(1);
    }
  }
})();
